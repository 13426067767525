import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Container from './Component/layout/Container'
function App() {
  <scrip>

  </scrip>
  // BreadButter.ui.gateRoute(["https://cdn.breadbutter.io/dist/breadbutter.5.5.0.462.min.js"], "http://localhost:3000");
 
  return (
    <div className="App">

      <Container/>
      
    </div>
  );
}

export default App;
