import React, { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
const Naviget = () => {
  const location = useLocation();
  const { id } = useParams();
  const pathname = window.location.pathname;
  let ids = sessionStorage.getItem("cabin");
  let clsasdd = sessionStorage.getItem("class");
  const onclick = () => {
    sessionStorage.setItem("class", "ddddd");
  };
 
  $(document).ready(function () {
    $("#demo").click(function () {
      $("#1").show();
    });
    $("#demo1").click(function () {
      $("#2").show();
    });
    $("#demo2").click(function () {
      $("#3").show();
    });
    $("#demo3").click(function () {
      $("#4").show();
    });
    $("#demo4").click(function () {
      $("#5").show();
    });
    $("#demo5").click(function () {
      $("#6").show();
    });
    $("#demo6").click(function () {
      $("#7").show();
    });
  });
  return (
    <div>
      <div class="container">
        <nav class=" margin-top ">
          <div
            class="nav-custom-class nav-war-main-wrap"
            id="navbarNavAltMarkup"
          >
            <NavLink exact to="/" id="demo" onClick={onclick} for="checkbox1">
              
              <i className=" icon ">
                <div className="ppp" id="1">
                  {" "}
                </div>
              </i>
              <span>Cabin</span>
            </NavLink>
            <NavLink
              exact
              className={clsasdd === "floorplan" ? "active" : null}
              // exact
              to={{ pathname: `/floorplan/${ids ? ids : "1"}` }}
              id="demo1"
              onClick={onclick}
              for="checkbox2"
            >
            
              <i className="icon ">
                <div className="ppp" id="2">
                  {" "}
                </div>
              </i>
              <span form="checkbox2">Floor plan </span>
            </NavLink>
            <NavLink
              exact
              className={clsasdd === "kitchen" ? "active" : null}
              // exact
              to={{ pathname: `/kitchen/${ids ? ids : "1"}` }}
              id="demo2"
              onClick={onclick}
              for="checkbox3"
            >             
              <i className="icon ">
                <div className="ppp" id="3">
                  {" "}
                </div>
              </i>
              <span>Kitchen</span>
            </NavLink>
            <NavLink
              exact
              className={clsasdd === "bathroom" ? "active" : null}
              // exact
              to={{ pathname: `/bathroom/${ids ? ids : "1"}` }}
              id="demo3"
              onClick={onclick}
              for="checkbox4"
            >
            <i className="icon ">
                <div className="ppp" id="4">
                  {" "}
                </div>
              </i>
              <span>Bathroom</span>
            </NavLink>
            <NavLink
              exact
              className={clsasdd === "premium" ? "active" : null}
              // exact
              to={{ pathname: `/premium/${ids ? ids : "1"}` }}
              id="demo4"
              onClick={onclick}
              for="checkbox5"
            >
              
              <i className="icon ">
                <div className="ppp" id="5">
                  {" "}
                </div>
              </i>
              <span>upgrades</span>
            </NavLink>
            <NavLink 
               exact
              className={clsasdd === "overview" ? "active" : null}
               to={{pathname:`/overview/${ ids  ? ids : "1" }`}} 
               id="demo5">
                 {" "}
                <i className="icon">
                <div className="ppp" id="6"></div>
                 </i>
                 <span>delivery</span>
              </NavLink>
            <NavLink
              exact
              className={clsasdd === "confirm" ? "active" : null}
              to="/confirm"
              id="demo6"
              onClick={onclick}
              for="checkbox6"
            >             
              <i className="icon ">
                <div className="ppp" id="7">
                  {" "}
                </div>
              </i>
              <span>confirm</span>
            </NavLink>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Naviget;












