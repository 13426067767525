import React, { useState, useEffect } from "react";
import { Card, Button, InputGroup, Form, Carousel } from "react-bootstrap";
import $ from "jquery";
import "./Select.css";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { kitcheen } from "../../Configuration";
import Loader from "./Loader";
const Kitchen = (props) => {
  function kitch() {
    let akitch = props.kitchens.kitchenName;
    // sessionStorage.setItem('price', akitch )    
  }
  let a = sessionStorage.getItem("fl_price");
  let b = sessionStorage.getItem("fl_unit");
  let c = sessionStorage.getItem("fl_dim");
  let cabin_unit = sessionStorage.getItem("cabin_unit")
  let cabin_dim = sessionStorage.getItem("cabin_dimansion")
  // let cabin_home = sessionStorage.getItem('cabin_house')
  let cabin_prices = sessionStorage.getItem('cabin_price')
  const { id, testvalue } = useParams();
  const location = useLocation();
  // console.log("location", location.state)
  const [isLoader, setLoading] = useState(true);
  const [kitchen, setKitchen] = useState([]);
  const [query, setQuery] = useState();
  const loadUser = async () => {
    const res = await axios.get(`${kitcheen}`);
    setKitchen(res.data);
    setLoading(false);
  };
  function searchQuery(evt) {
    const value = evt.target;
    setQuery(value);
  }
  useEffect(() => {
    loadUser();
    sessionStorage.setItem("class", "ddddd");
  }, []);
  function numberWithCommas(x) {
    return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  //   return x.toString().replace(/.(?=(..)*...$)/g, '$&,');
 }
  $(document).ready(function () {
    $(".exterior-cladding > div").click(function () {
      $(this).siblings().removeClass("is_active");
      $(this).addClass("is_active");
    });
    $(".p_btn").click(function () {
      $("#3").show();
    });
  });
  return isLoader ? (
    <div className="loadding">
      <Loader />
    </div>
  ) : (
    <div >
      {kitchen.map((kitchens) => {
        return (
          <div className="exterior-main floorplan-main-wrap interior-page kitchen-page">
            <div className="design-change-kitchen-page">
              <div className="container">
                <div className="row fix_height">
                  <div className="col-md-8 mt-4 title_img">
                    <div className=" ">
                      <div className="is_active">
                        <h2 className="title">{kitchens.type} Kitchen Module</h2>
                        {
                          kitchens.points.map(kit => {
                            return (
                              <div>
                                <li><b><b>{kit.point}</b></b></li>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mt-4  select-modal-slider">
                    <div class="summery-main right-box">
                      <h2 className="price_title">
                        {" "}
                        <p>{kitchens.type}</p>
                        <strong>
                          {" "}
                          ${numberWithCommas(
                            kitchens.price
                          )} <span></span>
                        </strong>
                      </h2>
                      <p className="kitchen_dis">{kitchens.kitchenDescription}</p>
                      <div class="summary-data">
                        <div className="text_p">
                          <p> Cabin ( {cabin_unit} ) </p>
                          <p> {cabin_dim}</p>
                        </div>
                        <div className="kitchen_value">
                          <p>
                            <strong>${numberWithCommas(
                              cabin_prices
                            )}</strong>
                          </p>
                        </div>
                      </div>                    
                      <div class="summary-data no_border">
                        <div className="text_p">
                          <p>Total Price</p>
                        </div>
                        <div className="kitchen_value">
                          <p>
                            <strong>
                              {/* ${numberWithCommas(
                              Number(cabin_prices) + 
                              // Number(a) +
                              Number(kitchens.price) 
                            ) */
                              }
                              ${numberWithCommas((Number(cabin_prices) +
                                // Number(a) +
                                Number(kitchens.price)).toFixed(2))}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div class="price price-value">
                        <Link
                          type="button"
                          to={{
                            pathname: `/bathroom/${id}`
                            // state:
                            //   parseInt(location.state) +
                            //   parseInt(kitchens.price),
                          }}
                          class="custem-btn p_btn "
                          onClick={() => {
                            let Kit_idd = kitchens.kitchen_id;
                            let Kit_Name = kitchens.kitchenName;
                            let Kit_type = kitchens.type;
                            let Kit_dimensions = kitchens.dimensions;
                            let Kit_unitSize = kitchens.unitSize;
                            let Kit_price = kitchens.price;
                            sessionStorage.setItem("kits_idd", Kit_idd);

                            sessionStorage.setItem("kit_name", Kit_Name);
                            sessionStorage.setItem("kit_type", Kit_type);
                            sessionStorage.setItem("dimension", Kit_dimensions);
                            sessionStorage.setItem("unitSizes", Kit_unitSize);
                            sessionStorage.setItem("unitprice", Kit_price);
                            sessionStorage.setItem('class', "bathroom")
                            sessionStorage.setItem("idd", kitchens.floorPlan_id)

                          }}
                        >
                          Select Kitchen
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="container ">
        <div className="no_button " style={{ marginRight: "15px" }}>
          <Link
            type="button"
            to={{ pathname: `/floorplan/${id}` }}
            class="custem-btn p_btn back btn_kit_sect_back"
          >
            Back
          </Link>
          <Link
            type="button"
            to={{ pathname: `/bathroom/${id}` }}
            class="custem-btn p_btn"
            onClick={() => {
              sessionStorage.setItem("idd", id)
              sessionStorage.setItem("unitprice", "0");
              sessionStorage.setItem('class', "bathroom")
            }}
          >
            No Kitchen
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Kitchen;
