import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import $ from "jquery";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { premiumUpgradess } from "../../Configuration";
import Loader from "./Loader";
const Premium = (props) => {

  const { cabin_id } = useParams();
  const location = useLocation();
  const [isLoader, setLoading] = useState(true);
  const [premium, setPremium] = useState([]);

  let cabin_prices = sessionStorage.getItem('cabin_price')
  let fl_Price = sessionStorage.getItem('fl_price')
  let testpric = sessionStorage.getItem("unitprice")
  let testpric_1 = sessionStorage.getItem("bathPrices")

  const loadUser = async () => {
    const res = await axios.get(`${premiumUpgradess}/${cabin_id}`);
    setPremium(res.data);
    setLoading(false);
  };

  // console.log("ppppppppp", premium)
  useEffect(() => {
    loadUser();
    sessionStorage.setItem("class", "ddddd");

  }, []);

  $(document).ready(function () {
    $(".p_btn").click(function () {
      $("#5").show();
    });
  });

  function numberWithCommas(x) {
    return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  }

  return isLoader ? (
    <div className="loadding">
      <Loader />
    </div>
  ) : (
    <div>
      <div className="upgra_prem">
        <div className="container">

          <div className="row fix_height">
            <div className="col-md-8 mt-4 col-md-space-class title_img">
              <div className="col-md-space-class ">
                <h2 className="title">{premium[0].type} Build</h2>
                <div className="select_img">
                  {/* <img className="premium_img" src={prem.upgradeImage} /> */}
                  {
                    premium[0].classic.map(pp => {
                      return (
                        <li><b>{pp.points}</b></li>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4  select-modal-slider">
              <div class="summery-main right-box">
                <h2 className="price_title1"><p>{premium[0].type}</p>

                  <p>
                    {
                      premium[0].price === null ? "N/A" :
                      <strong>${numberWithCommas(premium[0].price)}</strong>
                    }
                  </p>



                </h2>
                <div class="summary-data  ">
                  {/* <div className="text_p">
                    <p> ( {premium[0].type} ) </p>
                  </div> */}
                  {/* <div className="kitchen_value">
                    <p>
                      {
                        premium[0].price === null ? "N/A" :
                          <strong>${numberWithCommas(premium[0].price)}</strong>
                      }
                    </p>
                  </div> */}
                  <p className="premium_dis">{premium[0].description}</p>
                </div>

                <div className="kitchen_value space_pre">
                  <p>
                    {
                      premium[0].price !== null ?
                        <strong>${numberWithCommas(
                          Number(cabin_prices) +
                          // Number(fl_Price) +
                          Number(testpric) +
                          Number(testpric_1)
                        )
                        }
                        </strong>
                        :
                        <strong>${numberWithCommas(
                          (Number(cabin_prices) +
                            // Number(fl_Price) +
                            Number(testpric) +
                            Number(testpric_1)).toFixed(2)
                        )
                        }
                        </strong>
                    }
                  </p>
                </div>

                <div class="summary-data no_border">
                  <div className="text_p">
                    <p>Total Price</p>
                  </div>
                  <div className="kitchen_value">
                    <p>
                      <strong>${numberWithCommas(
                        (Number(cabin_prices) +
                          // Number(fl_Price) +
                          Number(testpric) +
                          Number(testpric_1) +
                          Number(premium[0].price)).toFixed(2)
                      )}
                      </strong>
                    </p>
                  </div>
                </div>

                <div class="price price-value">
                  <Link
                    type="button"
                    class="btn btn-outline-secondary custem-btn p_btn"
                    to={{
                      pathname: `/overview/${premium[0].cabin_id}`
                      // to={{ pathname: `/confirm`,
                      // state: parseInt(prem.upgradePrice),

                    }}

                    onClick={() => {
                      let pre_idd = premium[0].buildupgrade_id
                      let prem_type = premium[0].type;
                      let prem_price = (premium[0].price == null ? 0 : premium[0].price)
                      sessionStorage.setItem("idd", premium[0].id)
                      sessionStorage.setItem("prem_price", prem_price);
                      sessionStorage.setItem("prem_type", prem_type);
                      sessionStorage.setItem("prem_idd", pre_idd)
                      sessionStorage.setItem('class', "overview")

                    }}
                  >
                    No upgrade
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row fix_height">
            <div className="col-md-8 mt-4 col-md-space-class title_img">
              <div className="col-md-space-class ">
                <h2 className="title">{premium[1].type} Build</h2>
                <div className="select_img">
                  {/* <img className="premium_img" src={prem.upgradeImage} /> */}
                  {
                    premium[1].classic.map(pp => {
                      return (
                        <li><b>{pp.points}</b></li>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4  select-modal-slider">
              <div class="summery-main right-box">
                <h2 className="price_title1"><p>{premium[1].type}</p>
                  <p>
                    {
                      premium[1].price === null ? "N/A" :
                        <strong>${numberWithCommas(premium[1].price)}</strong>
                    }

                  </p>

                </h2>
                <div class="summary-data  ">
                  {/* <div className="text_p">
                    <p> ( {premium[1].type} ) </p>
                  </div> */}
                  {/* <div className="kitchen_value">
                    <p>
                      {
                        premium[1].price === null ? "N/A" :
                          <strong>${numberWithCommas(premium[1].price)}</strong>
                      }
                    </p>
                  </div> */}
                  <p className="premium_dis">{premium[1].description}</p>
                </div>

                <div className="kitchen_value space_pre">
                  <p>
                    {
                      premium[1].price !== null ?
                        <strong>${numberWithCommas(
                          (Number(cabin_prices) +
                            // Number(fl_Price) +
                            Number(testpric) +
                            Number(testpric_1)).toFixed(2)
                        )
                        }
                        </strong>
                        :
                        <strong>${numberWithCommas(
                          (Number(cabin_prices) +
                            // Number(fl_Price) +
                            Number(testpric) +
                            Number(testpric_1)).toFixed(2)
                        )
                        }
                        </strong>
                    }

                  </p>

                </div>

                <div class="summary-data no_border">
                  <div className="text_p">
                    <p>Total Price</p>
                  </div>
                  <div className="kitchen_value">
                    <p>
                      <strong>${numberWithCommas(
                        (Number(cabin_prices) +
                          // Number(fl_Price) +
                          Number(testpric) +
                          Number(testpric_1) +
                          Number(premium[1].price)).toFixed(2)

                      )
                      }
                      </strong>
                    </p>
                  </div>
                </div>

                <div class="price price-value">

                  <Link
                    type="button"
                    class="btn btn-outline-secondary custem-btn p_btn"
                    to={{
                      pathname: `/overview/${premium[1].cabin_id}`
                      // to={{ pathname: `/confirm`,
                      // state: parseInt(prem.upgradePrice),
                    }}

                    onClick={() => {
                      let pre_idd = premium[1].buildupgrade_id
                      let prem_type = premium[1].type;
                      let prem_price = (premium[1].price == null ? 0 : premium[1].price)

                      sessionStorage.setItem("prem_idd", pre_idd)
                      sessionStorage.setItem("idd", premium[1].id)
                      sessionStorage.setItem("prem_price", prem_price);
                      sessionStorage.setItem("prem_type", prem_type);
                      sessionStorage.setItem('class', "overview")

                    }}
                  >
                    select
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="no_button">
          <Link
            type="button"
            class="custem-btn  p_btn back"
            to={{ pathname: `/bathroom/${cabin_id}` }}
          >
            Back
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Premium;






