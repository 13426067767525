import React, { useState, useEffect, useRef } from "react";
import "./Select.css";
import sliderimg from "./imges/sliderimg.png";
import { Link, useParams } from "react-router-dom"
import axios from "axios";
import $ from "jquery";
import Googleaddress from '../pages/Googleaddress'
// import $, { now } from "jquery";
let autoComplete;
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, updateApiAddress) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "nz" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, updateApiAddress)
  );
}
var componentForm = {
  street_number: 'long_name',
  route: 'long_name',
  sublocality_level_1: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'long_name',
  country: 'long_name',
  postal_code: 'long_name'
};

async function handlePlaceSelect(updateQuery, updateApiAddress) {
  let newamp = {};
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  const y = addressObject.address_components;
  $(document).ready(function () {
    var map = new Map();
    // console.log('y++++++++ ' + y.length);
    for (i = 0; i < y.length; i++) {
      // console.log(y[i].types[0] + " = " + y[i].long_name);
      map[y[i].types[0]] = y[i].long_name;
      sessionStorage.setItem(y[i].types[0], y[i].long_name);
    }

    var test = map;
    // let locality = (test.locality === undefined && test.locality === "undefined" ? test.administrative_area_level_1 :test.administrative_area_level_1)
    let locality = (test.locality === undefined || test.locality === "undefined" ? test.locality : test.locality)
   
    sessionStorage.setItem("gg", locality)
    console.log('mp ++', test);
    // console.log('mp ++', map);

  });

  for (var i = 0; i < addressObject.address_components.length; i++) {
    // alert(i)
    var addressType = addressObject.address_components[i].types[4];
    var val = addressObject.address_components[i][componentForm[addressType]];
    // document.getElementById(addressType).value = val;
    // alert(addressType + "   " + val)
    newamp[addressType] = val;
  }
  updateApiAddress(JSON.stringify(newamp))
}
const Overview = (props) => {

  const [query, setQuery] = useState(props.value || "");
  const [googleApiAddress, setApiAddress] = useState(props.dataitem || {});
  const autoCompleteRef = useRef(null);
  function removeError() {
    $('.error').html('');
  }
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDKuUGMz8-xYyc6t1YWUhygrrkB4-WAFeY&libraries=places&callback=initAutocomplete`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setApiAddress)
    );
    loadUser()
    sessionStorage.setItem("class", "ddddd");
  }, []);

  const { id } = useParams()
  const [summary, setSummarty] = useState([]);
  const [checked, setChecked] = useState(false);
  let testid = sessionStorage.getItem("cabin")

  const loadUser = async () => {
    const result = await axios.get(`https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/model/location/${testid}`)
    setSummarty(result.data)
  }
  // const numberWithCommas = require('comma-number')

  $(document).ready(function () {
    $(".p_btn").click(function () {
      $("#6").show();
    });
  });

  return (
    <div className=" delivery">
      <div className="container">
        <div className="row">
          <div className="select-modal-slider">
            <div className=" ">
              <div className="delivery">
                <div className="input">
                  <div className="search-location-input">
                    <input
                      type="text"
                      onKeyPress={removeError}
                      ref={autoCompleteRef}
                      onChange={event => setQuery(event.target.value)}
                      value={query}
                      defaultValue={props.value}
                      placeholder={props.placeholder}
                      className="form-select"
                      id={props.id}
                      data-val={googleApiAddress}
                      required={true}
                      MaxLength="20 Characters"

                    />
                  </div>
                  <textarea placeholder="YOUR NOTE"></textarea>
                </div>
                <div className="no_address">
                  {query !== "" && query !== "" ?
                    <Link
                      className="custem-btn p_btn"
                      to={{ pathname: "/confirm" }}
                      onClick={() => {
                        sessionStorage.setItem("aa", 0)
                        summary.map(sum => {

                          let localitys = sessionStorage.getItem("gg" )
                          if (sum.location === localitys) {

                            var loc_price = sum.price;
                             sessionStorage.setItem("aa",loc_price)
                             sessionStorage.setItem("idds", id)
                          }
                        })
                      }}
                    >
                      This is my confirmed location
                    </Link> :
                    < button className="custem-btn p_btn locationbtn">
                      Please  Enter Your  location
                    </button>}

                  <Link
                    className="custem-btn p_btn"
                    to={{ pathname: "/confirm" }}
                    onClick={() => {
                      sessionStorage.setItem("aa", "00")
                    }}
                  >
                    I don’t have a confirmed location yet
                  </Link>
                </div>
              </div>
              <div className="back_con_btn">
                <Link
                  className="custem-btn p_btn back"
                  to={{ pathname: `/premium/${testid}` }}
                >
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Overview;
