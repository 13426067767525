import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from 'axios';
import "./Select.css";
import $ from 'jquery';
import { homeapi } from "../../Configuration";
import Loader from "./Loader";
const SelectModel = () => {
  const location = useLocation()
  const [isLoader, setLoader] = useState(true)
  const [select, setSelect] = useState([]);
  const { id } = useParams()
  const loadUser = async () => {
  const result = await axios.get(`${homeapi}`)
    setSelect(result.data)
    setLoader(false)
  }
  useEffect(() => {
    sessionStorage.setItem("class", "ddddd");
    loadUser()
  }, []);
  $(document).ready(function () {
    $('.description').click(function () {
      $(this).toggleClass('co_des');
    });
    $(".p_btn").click(function () {
      $("#1").show();
    });
  });
  function numberWithCommas(x) {
    return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
    // return x.toString().replace(/.(?=(..)*...$)/g, '$&,');
  }
  return isLoader ? (
    <div className="loadding">     
      <Loader />
    </div>
  ) : (
    <div className="floorplan-main-wrap select_mod-page">
      { 
        select.map((modal, index) => {
          return (
            <div className="container" key={index}>
              <div className="row">
                <div className="select-modal-slider">
                  <div className=" col-md-8 mt-4 img-slider">
                    <Carousel interval={null}>
                     {
                        modal.image.map(imgs => (
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={imgs.image_url}
                              alt="First slide"
                              rel="preload"
                              as="image"
                            />
                          </Carousel.Item>
                        ))}
                    </Carousel>
                  </div>
                  <div className="col-md-4 mt-4 slider-text">
                    <div className="text-inner">
                      <h2 className="title"> {modal.homeName}</h2>
                   <div className="score_mit">
                   <p><b> {modal.unitSize} </b></p>
                      <p><b> {modal.diminsions} </b></p>
                   </div>
                      <span className="value">
                        <strong> ${
                         numberWithCommas((modal.price))
                        }</strong>
                        *incl GST
                      </span> 
                      <Link 
                        type="button"
                        class="btn btn-outline-secondary custem-btn p_btn "
                        exact to={{ pathname: `/floorplan/${modal.id}`, 
                       }}
                        onClick={()=>{
                        let Cab_homeName = (modal.homeName);
                        let Cab_unitsize = (modal.unitSize);
                        let Cab_dimansion = (modal.diminsions);
                        let Cab_price = (modal.price);
                        let cabin_id = modal.id
                                           
                        sessionStorage.setItem('cabin_dimansion', Cab_dimansion);
                        sessionStorage.setItem('cabin_unit',Cab_unitsize);
                        sessionStorage.setItem('cabin_price',Cab_price );
                        sessionStorage.setItem('cabin_house' , Cab_homeName)
                        sessionStorage.setItem('class' , "floorplan")
                        sessionStorage.setItem("babs_idd", cabin_id)
                      }}
                      >
                      Select Cabin
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
  </div>
  );
};
export default SelectModel;
