import React from "react";

const Loader = () => {
  return (
    <>
      <div id='outer'>
        <div id='middle'>
          <div id='inner'>
               
      </div>
      </div>
      </div>
      <p style={{fontSize: "16px"}}>Loading...</p>
   </>
  );
};
export default Loader;