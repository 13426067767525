import React,{useState} from "react";
import Header from '../Naves/Header';
import Naviget from "../Naves/Naviget";
import Footer from "../Naves/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Floorplan from '../pages/Floorplan';
import Kitchen from "../pages/Kitchen";
import Bathroom from "../pages/Bathroom";
import Overview from "../pages/Overview";
import SelectModel from "../pages/SelectModel";
import Premium from "../pages/Premium";
import Confirm from '../pages/Confirm';
import Loader from "../Naves/Loader";

const Container = () => {
  return (
    <Router>
      <Header />
      <Naviget />
      {/* <Loader/> */}
      <Switch>
        <Route exact path="/" component={SelectModel} />
        <Route exact path="/floorplan/:id" component={Floorplan} />
        <Route exact path="/kitchen/:id" component={Kitchen} />
        <Route exact path="/bathroom/:id" component={Bathroom} />
        <Route exact path="/premium/:cabin_id" component={Premium} />
        <Route exact path="/overview/:id" component={Overview} />
        <Route exact path="/confirm" component={Confirm} />        
      </Switch>
      {/* <Footer /> */}
    </Router>
  )
}
export default Container