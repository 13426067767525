import React, { useState, useEffect } from "react";
import { Carousel } from 'react-bootstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import "./Select.css";
import { floorplan } from "../../Configuration";
import Loader from "./Loader";
import $ from "jquery";
const Floorplan = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const [isLoader, setLoading] = useState(true);
  const [floor, setFloor] = useState([]);
  sessionStorage.setItem("cabin", id);
  let cabin_price = sessionStorage.getItem("cabin_price")
  const loadUser = async () => {
    const result = await axios.get(`${floorplan}/${id}`);
    setFloor(result.data);
    setLoading(false);
  };
  useEffect(() => {
    loadUser();
    sessionStorage.setItem("class", "ddddd");
  }, []);
  $(document).ready(function () {
    $(".p_btn").click(function () {
      $("#2").show();
    });
  });
  function numberWithCommas(x) {
    return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  }
  return isLoader ? (
    <div className="loadding">
      <Loader />
    </div>
  ) : (
    <div className="flor_cust padding_section">
      <div className="container">
        <div className="row">
          <div className="select-modal-slider">
            <div className=" col-lg-12 img-slider floor_img">
              <Carousel interval={null}>
                {
                  floor.image && floor.image.map(imgg => {
                    return (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          rel="preload"
                          as="image"
                          src={imgg.origenal_image}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <section className="for-bg-color graph-map">
        <div className="container">
          <div className="row">
            {
              floor.floorplan && floor.floorplan.map(fl => {
                return (
                  <div className="select-modal-slider">
                    <div className=" col-md-5 mt-4 img-slider">
                      {
                        fl.images && fl.images.map(graph => {
                          return (
                            <img
                              className="d-block w-100"
                              rel="preload"
                              as="image"
                              src={graph.image_url}
                              alt="First slide"
                            />
                          )
                        })
                      }
                    </div>
                    <div className="col-md-7 mt-4 slider-text">
                      <div className="text-inner">
                        <h2 className="title">{fl.floorName} </h2>
                        {/* <p className="scroll_text"> {floor.description} </p> */}
                        {
                          fl.images && fl.images.map(graph => {
                            return (
                              <div className="">
                                <p className="scroll_text"><b>{fl.floorNumber}</b></p>
                              </div>
                            )
                          })
                        }
                        <table >
                          <tr>
                            <td><b>{floor.unitSize}</b></td>
                          </tr>
                          <tr>
                            {/* <td>{floor.diminsions}</td> */}
                            <td><b>{fl.floorDimensions}</b></td>
                          </tr>
                          <tr>
                            <td>{fl.description}</td>
                          </tr>
                          <tr>
                            <span className="value">
                              {/* <strong>${numberWithCommas(floor.price)}</strong> */}
                            </span>
                          </tr>
                        </table>
                        <span className="value">
                          
                          {/* <p>Total Price</p> */}
                          {/* <strong>${numberWithCommas(
                             Number(cabin_price) +
                             Number(fl.price)
                          )}</strong> */}

                        </span>
                        <Link
                          type="button"
                          class="btn btn-outline-secondary custem-btn p_btn"
                          to={{
                            pathname: `/kitchen/${floor.id}`,
                            //  state: (parseInt(location.state) + parseInt(fl.price))
                          }}
                          onClick={() => {
                            let ids=fl.id
                            let a = (fl.price)
                            let b = (fl.floorName)
                            let c = (fl.floorNumber)
                            let F_summ = floor.description

                            sessionStorage.setItem("fl_ids", ids)
                            sessionStorage.setItem("fl_price", a)
                            sessionStorage.setItem("fl_dim", b)
                            sessionStorage.setItem("fl_unit", c)
                            sessionStorage.setItem("fl_sum", F_summ)
                            sessionStorage.setItem('class', "kitchen")
                            sessionStorage.setItem("idd", floor.id)
                          }}
                        >
                         SELECT FLOORPLAN
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      <div className="container">
        <div className="nxt_back_btn">
          <Link
            type="button"
            class="btn btn-outline-secondary custem-btn p_btn back"
            to={{
              pathname: `/`
            }}
            onClick={() => {
              sessionStorage.setItem('class', "/")
              sessionStorage.setItem("idd", floor.id)
            }}>
            back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Floorplan;




