import { Link } from "react-router-dom";
import "../Naves/Header.css";
import logo from "../pages/imges/logo.png";
import $ from 'jquery';
const Header = () => {
  $(document).ready(function () {
    $('.toggle_menu').click(function () {
      $('.navigation').toggleClass('slider_menu');
      $( this ).toggleClass('toggle_menu_co');
      $( '.logo' ).toggleClass('logo_co');
      $('.con_tel').toggleClass('con_tel_slide');
    });
    $("li").click(function () {
      $(this).siblings().removeClass("active");
      $(this).addClass("active");
    });
  }); 
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header-inner">
            <div className="logo">
              <Link>
                <img src={logo} alt="Girl in a jacket"/>
              </Link>
            </div>
            <div className="toggle_menu"><span></span></div>
            <div className="contact">
              <ul className="con_tel">
                <li>
                  <a href="mailto:HELLO@IESL.COM"><i class="fa fa-envelope-o" aria-hidden="true"></i> HELLO@KOLTM.COM</a>
                </li>
                <li>
                  <a href="tel:0282 554 968"><i class="fa fa-mobile" aria-hidden="true"></i> 0282 554 9685</a>
                </li>
              </ul>
            </div>
            <div className="menu">
              <nav className="navigation">
                <ul>
                  <li className="active">
                    <a href="/" >HOME</a>
                  </li>
                  <li>
                    <a href="#">INFO</a>
                  </li>
                  <li>
                    <a href="#">OUR RANGE</a>
                  </li>
                  <li>
                    <a href="#">GALLERY</a>
                  </li>
                  <li>
                    <a href="#">BLOG</a >
                  </li>
                  <li>
                    <a href="#">CONTACT</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;