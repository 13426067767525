// export const homeapi = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/basemodel";
// export const floorplan = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/basemodel";
// // export const kitcheen = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/kitchen";
// export const kitcheen = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/kitchen";

// export const bathroomss = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/bathroom";
// export const premiumUpgradess = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/buildUpgrade";
// export const overviews = "https://n7i4l0vf57.execute-api.ap-southeast-2.amazonaws.com/dev2/koltm/model/location";


export const homeapi = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/basemodel";
export const floorplan = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/basemodel";
// export const kitcheen = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/kitchen";
export const kitcheen = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/kitchen";

export const bathroomss = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/bathroom";
export const premiumUpgradess = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/buildUpgrade";
export const overviews = "https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/model/location";
