import React, { useEffect, useState } from "react";
import { Card, Button, InputGroup, Form, Carousel } from "react-bootstrap";
import $ from "jquery";
import { NavLink, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { bathroomss } from "../../Configuration";
import Loader from "./Loader";
import Confirm2 from './Confirm2'
// import { useNavigate  } from "react-router-dom";

const Bathroom = () => {
  // const navigate = useNavigate();
  let cabin_unit = sessionStorage.getItem("cabin_unit")
  let cabin_dim = sessionStorage.getItem("cabin_dimansion")
  let cabin_home = sessionStorage.getItem('cabin_house')
  let cabin_prices = sessionStorage.getItem('cabin_price')

  // let fl_houseName = sessionStorage.getItem('fl_dim')
  // let fl_Nums = sessionStorage.getItem('fl_unit')
  // let fl_Price = sessionStorage.getItem('fl_price')

  let Kit_types = sessionStorage.getItem('kit_type')
  let testpric = sessionStorage.getItem("unitprice")

  const { id } = useParams();
  const location = useLocation();
  const [isLoader, setLoading] = useState(true);
  const [bathroom, setBathroom] = useState([]);
  const loadUser = async () => {
    const res = await axios.get(`${bathroomss}`);
    setBathroom(res.data);
    setLoading(false);
  };
  useEffect(() => {
    loadUser();
    sessionStorage.setItem("class", "ddddd");
  }, []);
  $(document).ready(function () {
    $(".p_btn").click(function () {
      $("#4").show();
    });
  });
  function numberWithCommas(x) {
    return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  }
  return isLoader ? (
    <div className="loadding">
      <Loader />
    </div>
  ) : (
    <div>
      {bathroom.map((bathrooms) => {
        return (
          <div className="exterior-main floorplan-main-wrap  interior-page bathroom-page">
            <div className="container">
              <div className="row fix_height">

                <div className="col-md-8 mt-4 col-md-space-class title_img">
                  <div className="col-md-space-class ">
                    {/* <h2 className="title">{bathrooms.BathroomName}</h2> */}
                    <h2 className="title">{bathrooms.type} Bathroom Module</h2>

                    <div>
                      <div className="">
                        <div className="is_active">
                          {/* <img src={bathrooms.BathroomImage} /> */}
                          {
                            bathrooms.points && bathrooms.points.map(bath => {
                              return (
                                <div>
                                  <li><b>{bath.point}</b></li>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-4  select-modal-slider  ">
                  <div class="summery-main right-box">
                    {/* <h2>{bathrooms.BathroomName}</h2> */}
                    <h2 className="price_title">
                      {" "}
                      <p>{bathrooms.type}</p>
                      <strong>
                        {" "}

                        ${numberWithCommas(bathrooms.price)} <span></span>
                      </strong>
                    </h2>
                    <p className="kitchen_dis">{bathrooms.description}</p>
                    <div class="summary-data">
                      <div className="text_p">
                        <p> Cabin ( {cabin_unit} )  </p>
                        <p>( {cabin_dim} ) </p>
                      </div>
                      <div className="kitchen_value">
                        <p>
                          <strong>${numberWithCommas(cabin_prices)}</strong>
                        </p>
                      </div>
                    </div>

                    {/* ..........floorplan.............! */}
                    {/* <div class=
                      {
                        testpric === "0"
                        ?
                        "summary-data kitchen_space"
                        :
                        "summary-data"

                      }>
                      <div className="text_p">
                        <p>Floor Plan ( {fl_houseName} ) </p>
                        <p>{fl_Nums}</p>
                      </div>
                      <div className="kitchen_value">
                        <p>
                          <strong>${numberWithCommas(fl_Price)}</strong> 
                        </p>
                      </div>
                     </div> */}

                    {/* ..........floorplan.............! */}


                    {
                      testpric === "0"
                        ?
                        null :
                        <div class="summary-data">
                          <div className="text_p">
                            <p> Kitchen ( {Kit_types} )</p>
                          </div>
                          <div className="kitchen_value">
                            <p>
                              <strong>${numberWithCommas(testpric)}</strong>
                            </p>
                          </div>
                        </div>
                    }

                    <div class="summary-data no_border">
                      <div className="text_p">
                        <p>Total Price</p>
                      </div>
                      <div className="kitchen_value">
                        <p>
                          <strong>${numberWithCommas(
                            (Number(cabin_prices) +
                              // Number(fl_Price) +
                              Number(testpric) +
                              Number(bathrooms.price)).toFixed(2)
                          )
                          }

                          </strong>
                        </p>
                      </div>
                    </div>
                    <div class="price price-value">

                      <NavLink
                        className="custem-btn p_btn"
                        to={{
                          pathname: `/premium/${id}`
                          // state: (parseInt(location.state) + parseInt(bathrooms.BathroomPrice))
                        }}

                        onClick={() => {
                          let bathIdd = bathrooms.id
                          let bath_type = bathrooms.type
                          let bathPrice = bathrooms.price

                          sessionStorage.setItem("bathid", bathIdd)
                          sessionStorage.setItem("bathType", bath_type)
                          sessionStorage.setItem("bathPrices", bathPrice)
                          sessionStorage.setItem('class', "premium")
                          sessionStorage.setItem("idd", bathroom.floorPlan_id)

                        }}
                      >
                        Select Bathroom
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="container">
        <div className="no_button" style={{ marginRight: "15px" }}>
        <NavLink
            type="button"
            to={{ pathname: `/kitchen/${id}` }}
            class="custem-btn p_btn back btn_kit_sect_back"
          >
            Back
          </NavLink>     
          <NavLink
            type="button"
            to={{ pathname: `/premium/${id}`, state: parseInt(location.state) + 0 }}
            class="custem-btn p_btn"
            onClick={() => {
              sessionStorage.setItem('class', "premium")
              sessionStorage.setItem("bathPrices", "0")
              sessionStorage.setItem("idd", id)

            }}
          >
            No Bathroom
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Bathroom;
