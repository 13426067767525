import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import $ from 'jquery';

const Confirm = (props) => {
  const history = useHistory()
  function numberWithCommas(x) {
    return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  }

  const [user2, setUser2] = useState();
  const [user, setUser] = useState();
  const [user1, setUser1] = useState({
    Notes: ""
  });
  
  let data1, data2;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [message, setMessage] = useState(false)
  const [messageIs, setIsMessage] = useState(false)

  const loadUsers = async (Event) => {
    // alert(typeof user);
    if (user1.Notes === "" || user1 == "" ) {
      data1 = {

        "cabin_id": cabins_ids,
        "cabin_name": cabin_home,
        "cabin_diminsions": cabin_dim,
        "cabin_unitSize": cabin_unit,
        "cabin_price": cabin_prices,
        "floorplan_id": fl_idd,
        "floorName": fl_houseName,
        "floorNumber": fl_Nums,
        "floor_price": "0",
        "kitchen_price": testpric,
        "kitchen_type": testpric === "0" ? null: Kit_types,
        "bathroom_price": testpric_1,
        "bathroom_type": testpric_1 === "0" ? null: Bath_type,
        "upgrade_price": premPrices,
        "upgrade_type": prem_type,
        "delivery_location":locationPrice==="00" ? null : bbs,
        "delivery_price": locationPrice,
        "total_investment_price": numberWithCommas(
          (Number(cabin_prices) +
            // Number(fl_Price) +
            Number(testpric) +
            Number(testpric_1) +
            Number(premPrices) +
            Number(locationPrice)).toFixed(2)
        ),
        "email": user,
        "delirery_id":locationPrice==="00" || locationPrice === "0"  ? null: location_id,
        "bathroom_id": testpric_1 === "0" ? null: Bath_idds,
        "kitchen_id": testpric === "0" ? null: Kit_idds,
        "floorplan_id": fl_idd,
        "cabin_id": cabins_ids,
        "username": user2,
        "upgrade_id": prem_idds

      }
    }
    
    else {
      data1 = {
        "Notes": user1,
        "cabin_id": cabins_ids,
        "cabin_name": cabin_home,
        "cabin_diminsions": cabin_dim,
        "cabin_unitSize": cabin_unit,
        "cabin_price": cabin_prices,
        "floorplan_id": fl_idd,
        "floorName": fl_houseName,
        "floorNumber": fl_Nums,
        "floor_price": "0",
        "kitchen_price": testpric,
        "kitchen_type": testpric === "0" ? null: Kit_types,
        "bathroom_price": testpric_1,
        "bathroom_type": testpric_1 === "0" ? null: Bath_type,
        "upgrade_price": premPrices,
        "upgrade_type": prem_type,
        "delivery_location":locationPrice ==="00" ? null : bbs,
        "delivery_price": locationPrice,
        "total_investment_price": numberWithCommas(
          (Number(cabin_prices) +
            // Number(fl_Price) +
            Number(testpric) +
            Number(testpric_1) +
            Number(premPrices) +
            Number(locationPrice)).toFixed(2)
        ),
        "email": user,
        "delirery_id":locationPrice==="00"  || locationPrice === "0" ? null: location_id,
        "bathroom_id": testpric_1 === "0" ? null: Bath_idds,
        "kitchen_id": testpric === "0" ? null: Kit_idds,
        "floorplan_id": fl_idd,
        "cabin_id": cabins_ids,
        "username": user2,
        "upgrade_id": prem_idds
      }
    }
    setIsLoading(true);
    const res = await axios.post(`https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/userpdf/email`, data1)
      .then((res) => {

        Swal.fire({
          text: "Sent project estimate to your inbox",

        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })

        setIsLoading(false)
        setMessage(true)

      }).catch((error) => {

        setIsLoading(false)
        setMessage(true)
      })

  }

  const onClicked = async (Event, e) => {
    if (user1.Notes === "" || user1 == "") {
      data2 = {
        "cabin_id": cabins_ids,
        "cabin_name": cabin_home,
        "cabin_diminsions": cabin_dim,
        "cabin_unitSize": cabin_unit,
        "cabin_price": cabin_prices,
        "floorplan_id": fl_idd,
        "floorName": fl_houseName,
        "floorNumber": fl_Nums,
        "floor_price": "0",
        "kitchen_price": testpric,
        "kitchen_type": Kit_types,
        "bathroom_price": testpric_1,
        "bathroom_type": Bath_type,
        "upgrade_price": premPrices,
        "upgrade_type": prem_type,
        "delivery_location": locationPrice === "00" ? null : bbs,
        "delivery_price": locationPrice,
        "total_investment_price": numberWithCommas(
          (Number(cabin_prices) +
            // Number(fl_Price) +
            Number(testpric) +
            Number(testpric_1) +
            Number(premPrices) +
            Number(locationPrice)).toFixed(2)
        ),

        "delirery_id": locationPrice === "00" || locationPrice === "0" ? null : location_id,
        "bathroom_id": testpric_1 === "0" ? null: Bath_idds,
        "kitchen_id": testpric === "0" ? null: Kit_idds,
        "floorplan_id": fl_idd,
        "cabin_id": cabins_ids,
        "username": user2,
        "email": user,
        "upgrade_id": prem_idds
      }
    }
    else {
      data2 = {
        "Notes": user1,
        "cabin_id": cabins_ids,
        "cabin_name": cabin_home,
        "cabin_diminsions": cabin_dim,
        "cabin_unitSize": cabin_unit,
        "cabin_price": cabin_prices,
        "floorplan_id": fl_idd,
        "floorName": fl_houseName,
        "floorNumber": fl_Nums,
        "floor_price": "0",
        "kitchen_price": testpric,
        "kitchen_type": testpric==="0" ? null : Kit_types ,
        "bathroom_price": testpric_1,
        "bathroom_type": testpric_1 === "0" ? null : Bath_type,
        "upgrade_price": premPrices,
        "upgrade_type": prem_type,
        "delivery_location": locationPrice==="00"  ? null : bbs,
        "delivery_price": locationPrice,
        "total_investment_price": numberWithCommas(
          (Number(cabin_prices) +
            // Number(fl_Price) +
            Number(testpric) +
            Number(testpric_1) +
            Number(premPrices) +
            Number(locationPrice)).toFixed(2)
        ),

        "delirery_id":locationPrice === "00" || locationPrice === "0" ? null: location_id,
        "bathroom_id": testpric_1 === "0" ? null: Bath_idds,
        "kitchen_id": testpric === "0" ? null: Kit_idds,
        "floorplan_id": fl_idd,
        "cabin_id": cabins_ids,
        "email": user,
        "upgrade_id": prem_idds
      }
    }
    setIsLoadings(true);
    const res = await axios.post(`https://hcrt3co2ra.execute-api.ap-southeast-2.amazonaws.com/dev/koltm/team/mail`, data2)
      .then((res) => {
        Swal.fire({
          text: "Send project estimate to our Team",

        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })

        setIsLoadings(false)
        // Event.preventDefault()
        setIsMessage(true)
        setMessage(true)


        // alert("Data submit")
      }).catch((error) => {
        // alert("error")
        setIsLoadings(false)
        setIsMessage(true)

      })

  }

  let fl_id = sessionStorage.getItem("cabin");

  let cabins_ids = sessionStorage.getItem("babs_idd")
  let cabin_unit = sessionStorage.getItem("cabin_unit")
  let cabin_dim = sessionStorage.getItem("cabin_dimansion")
  let cabin_home = sessionStorage.getItem('cabin_house')
  let cabin_prices = sessionStorage.getItem('cabin_price')

  let fl_idd = sessionStorage.getItem('fl_ids')
  let fl_houseName = sessionStorage.getItem('fl_dim')
  let fl_Nums = sessionStorage.getItem('fl_unit')
  let fl_Price = sessionStorage.getItem('fl_price')
  let f_discri = sessionStorage.getItem('fl_ids')

  let Kit_idds = sessionStorage.getItem('kits_idd')
  let Kit_types = sessionStorage.getItem('kit_type')
  let testpric = sessionStorage.getItem("unitprice")

  let Bath_idds = sessionStorage.getItem('bathid')
  let Bath_type = sessionStorage.getItem("bathType")
  let testpric_1 = sessionStorage.getItem("bathPrices")

  let prem_idds = sessionStorage.getItem('prem_idd')
  let prem_type = sessionStorage.getItem("prem_type")
  let premPrices = sessionStorage.getItem("prem_price")

  let location_id = sessionStorage.getItem("idds")
  let bbs = sessionStorage.getItem("gg")
  let locationss = sessionStorage.getItem("locations")
  let locationPrice = sessionStorage.getItem("aa")

  const [errorS, setEerrorS] = useState(false);

  let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleClick = () => {

    // let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      typeof user2 == "undefined" ||
      user2 === "" ||
      user2 === null
    ) {
      $(".erroruser").html("Please enter your name")
    }

    else if (typeof user == "undefined" ||
      user === "" ||
      user === null) {

      $(".errorEmail").html("Please enter your email")
    }
    else if (emailExpression.test(user)) {
      loadUsers();
    }
    else {
      $(".errorEmail").html("Please enter valid email")
    }

  }
  function removeError() {
    $('.error').html('');
  }

  const handlesubmit = () => {

    // let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      typeof user2 == "undefined" ||
      user2 === "" ||
      user2 === null
    ) {
      $(".erroruser").html("Please enter user name")
    }
    else if (typeof user == "undefined" ||
      user === "" ||
      user === null) {
      $(".errorEmail").html("Please enter email")
    }
    else if (emailExpression.test(user)) {
      onClicked();
    }
    else {
      $(".errorEmail").html("Please enter valid Email")
    }
  }
  return (
    <div className="confirm ">
      <div className="container">
        <div className="row">
          <div className="col-md-7 select-modal-slider">
            <div class="summery-main">
              <h2 className="title">project summary</h2>
              <div class="summary-data">
                <div class="data">
                  <h5><b>Cabin</b></h5>
                  <h6> <b>{cabin_home}</b></h6>
                  <p>  {cabin_unit} </p>
                  <p> {cabin_dim}</p>
                </div>
                <div class="price">
                  <span>
                    <strong>${numberWithCommas(cabin_prices)}</strong></span>
                  <Link className="custem-btn"
                    exact to={{ pathname: "/" }}
                  >EDIT</Link>
                </div>
              </div>

              <div class="summary-data">
                <div class="data" style={{ position: "relative" }}>
                  <h5><b>Floor Plan</b></h5>
                  <h6> <b>{fl_houseName}</b></h6>
                  <h6> {fl_Nums}</h6>
                </div>
                <div class="price">
                  {/* <span>
                    <strong> ${numberWithCommas(fl_Price)}</strong></span> */}
                  <Link className="custem-btn"
                    exact to={{ pathname: `/floorplan/${fl_id}` }}
                    onClick={() => {
                      sessionStorage.setItem('class', "floorplan")
                    }}
                  >
                    EDIT</Link>
                </div>
              </div>

              {testpric === "0"
                ?
                null
                :
                <div class="summary-data">
                  <div class="data">
                    <h5><b>Kitchen</b></h5>
                    <p> <b>{Kit_types}</b></p>
                  </div>
                  <div class="price">
                    <span>
                      <strong> ${numberWithCommas(testpric)}</strong>
                    </span>
                    <Link className="custem-btn"
                      exact to={{ pathname: `/kitchen/${fl_id}` }}
                      onClick={() => {
                        sessionStorage.setItem('class', "kitchen")
                      }
                      }
                    >EDIT</Link>
                  </div>
                </div>
              }

              {
                testpric_1 === "0" ?
                  null
                  :
                  <div class="summary-data">
                    <div class="data"><h5><b>Bathroom</b></h5>
                      <p> <b>{Bath_type}</b></p>
                    </div>
                    <div class="price">
                      <span>
                        <strong> ${numberWithCommas(testpric_1)}</strong></span>
                      <Link className="custem-btn"
                        exact to={{ pathname: `/bathroom/${fl_id}` }}
                        onClick={() => {
                          sessionStorage.setItem('class', "bathroom")
                        }}

                      >EDIT</Link>
                    </div>
                  </div>
              }
              {
                premPrices === "0" ?
                  null : <div class="summary-data">
                    <div class="data"><h5><b>Upgrade</b></h5>
                      <p> <b>{prem_type}</b></p>
                    </div>

                    <div class="price">
                      <span>
                        <strong> ${numberWithCommas(premPrices)} </strong></span>
                      <Link className="custem-btn"
                        exact to={{ pathname: `/premium/${fl_id}` }}

                        onClick={() => {
                          sessionStorage.setItem('class', "premium")
                        }}
                      >
                        EDIT
                      </Link>
                    </div>
                  </div>
              }
              {/* ........................Delavry........................... */}
              {
                locationPrice === "0"
                  ?
                  <div>
                    <h5>Delivery Location</h5>
                    <p> Estimate available on request </p>
                  </div>

                  : locationPrice === "00" ? null
                    :
                    <div class="summary-data">
                      <div class="data"><h5><b>Delivery Cost</b></h5>
                        <p> <b>{bbs}</b></p>
                      </div>
                      <div class="price">
                        <span>
                          <strong>${numberWithCommas(locationPrice)}</strong>
                        </span>
                        <Link className="custem-btn"
                          exact to={{ pathname: `/overview/${fl_id}` }}
                          onClick={() => {
                            sessionStorage.setItem('class', "overview")
                          }}
                        >
                          EDIT
                        </Link>
                      </div>
                    </div>
              }

              {/* ........................Delavry........................... */}

              <div class="summary-data">
                <div class="data">
                  <h5>Total investment</h5>
                </div>
                <div class="price">
                  <span><strong>$
                    {numberWithCommas(
                      (Number(cabin_prices) +
                        // Number(fl_Price) +
                        Number(testpric) +
                        Number(testpric_1) +
                        Number(premPrices) +
                        Number(locationPrice)).toFixed(2)
                    )}
                  </strong>
                  </span>
                </div>
                <div>
                  <p>*All Price Incl GST</p>
                  <p>*All Price are in NZD</p>
                  <p>
                    *Building consent, foundations, site survey, Geotech & connection of
                    services are additional costs which are not included in this quote
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="confirm ">
              <div className=" confirm-page">
                <div className="  slider-text">
                  <div class="delivery">
                    {/* <form type="submit"> */}
                    <div class="input">
                      <input
                        name="fname"
                        type="text"
                        id="txtName"
                        placeholder="Enter your name"
                        onChange={(e) => {
                          removeError()
                          setEerrorS(false)
                          setUser2(e.target.value)
                        }
                        }
                      />
                      <p className="error erroruser"></p>
                      <input
                        type="text"
                        id="txtEmail"
                        placeholder="Enter your email"

                        onChange={(e) => {
                          // onInputChange(e)
                          removeError()
                          setEerrorS(false)
                          setUser(e.target.value)
                        }
                        }
                      />
                      <p className="error errorEmail"></p>
                      <textarea
                        value={user1.Notes}
                        onChange={(e) => setUser1(e.target.value)}
                        placeholder="Comments or additional requests."
                      ></textarea>
                    </div>
                    <div class="price">
                      {
                        isLoading === true ?
                          <button class="custem-btn" >
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                          :
                          <>
                            <button
                              class="custem-btn"
                              id="btnCheck"
                              onClick={event => {
                                handleClick(event);
                              }
                              }>
                              send project estimate to my inbox
                            </button>
                            <p class="error"></p>
                          </>
                      }
                    </div>
                   <div className="price">
                      <div className="no_button">

                        {isLoadings === true ?
                          <button class="custem-btn" >
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                          :

                          <button class="custem-btn" onClick={handlesubmit} >
                            send project estimate to our team
                          </button>

                        }
                      </div>
                    </div>
                    {/* </form> */}

                    <div className="price">
                      < div className="no_button">
                        <Link
                          type="button"
                          class="custem-btn  p_btn back"
                          to={{ pathname: `/overview/${fl_id}` }}
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Confirm;
























